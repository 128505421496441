import { EnvironmentInterface } from "src/environments/environment.model";

export const Environment: EnvironmentInterface = {
  production: true,
  environment: 'production',
  integrations: {
    sentry: {
      dns_client: 'https://52dbad7499b8934a42d450d179600f83@o913426.ingest.sentry.io/4506825214590976',
      dns_ssr: 'https://52dbad7499b8934a42d450d179600f83@o913426.ingest.sentry.io/4506825214590976',
    },
    plausible: {
      url: 'https://t.elitedomains.de',
      domain: 'elitedomains.de',
    },
    crisp: {
      id: 'd7fbc512-2754-48af-904c-9beb5e268642',
    }
  },
  url: {
    app: 'https://app.elitedomains.de',
    buy: 'https://buy.elitedomains.de',
    promo: 'https://elitedomains.de',
    checkout: 'https://checkout.elitedomains.de',
  }
}
